html,
body,
#root {
  min-width: 400px;
  height: 100%;
}

p {
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
}

h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 300;
}

.Colored {
  color: rgb(128 0 128);
}

.Pointer {
  cursor: pointer;
}

.Ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 30px;
  border-bottom: 1px solid black;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 300;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  .User {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 260px);

    > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button {
      width: auto;
      height: auto;
      margin-left: 8px;
      padding: 2px 4px;
    }

    @media screen and (max-width: 600px) {
      width: calc(100% - 220px);
    }
  }
}

nav {
  display: flex;
  flex-direction: row;
  height: 32px;

  div {
    padding: 4px 8px;
    border: 1px solid black;
    border-top: 0;
    border-left: 0;
    cursor: pointer;
    box-sizing: border-box;

    &.Colored {
      cursor: not-allowed;
    }
  }
}

main {
  height: calc(100% - 60px - 32px);
}

.Loader {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgb(0 0 0 / 90%);
  font-size: large;
  direction: column;
}

button {
  width: 200px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;

  &:enabled {
    &:hover {
      box-shadow: 2px 2px 0 black;
      cursor: pointer;
    }
  }

  &:disabled {
    background-color: rgb(220 220 220);
    opacity: 0.5;
  }
}

input {
  border: 1px solid black;
  border-radius: 5px;
  outline: none;

  &:focus {
    box-shadow: 2px 2px 0 black;
  }
}

.LoginPage,
.ChangePasswordPage,
.ForgotPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding-top: 60px;
  padding-bottom: 150px;

  h2 {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 5px;

    label {
      margin-top: 8px;
    }

    button[type='submit'] {
      margin-top: 24px;
    }

    span {
      margin-top: 8px;
    }
  }
}

.StandReservationPage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  direction: column;

  .StandReservations {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 20px;
    padding-top: 60px;
    padding-bottom: 150px;

    .StandReservation {
      width: 100%;
      padding: 15px;
      border: 1px solid black;
      border-radius: 15px;

      h3 {
        margin: 0;
        font-weight: 300;
      }

      hr {
        margin: 10px -15px;
        border: 0.5px solid black;
      }

      .StandReservationText {
        margin: 15px 0;

        p {
          margin: 0;
        }

        div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:first-child {
            color: rgb(128 0 128);
          }
        }
      }

      button {
        width: 100px;
        height: auto;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    @media screen and (max-width: 979px) {
      grid-template-columns: repeat(2, 300px);
    }

    @media screen and (max-width: 659px) {
      grid-template-columns: repeat(1, 300px);
    }
  }
}

.StandActionLogsPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 64px 48px 32px;

  h2,
  h3 {
    margin-bottom: 5px;
  }

  .Row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    height: 400px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .Cell {
      width: 50%;
      margin: 0 15px;
      padding: 10px;
      overflow-y: scroll;
      border: 1px solid black;
      border-radius: 5px;

      &:first-child {
        margin-left: 0;

        @media screen and (max-width: 799px) {
          margin-top: 0;
        }
      }

      &:last-child {
        margin-right: 0;

        @media screen and (max-width: 799px) {
          margin-bottom: 0;
        }
      }

      .StatsBlock:not(:first-child) {
        margin-top: 20px;
      }

      @media screen and (max-width: 799px) {
        width: 100%;
        max-height: 400px;
        margin: 15px 0;
      }
    }

    @media screen and (max-width: 799px) {
      flex-direction: column;
      height: unset;
    }
  }

  .StandActionLogs {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    box-sizing: border-box;

    .StandActionLog {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid black;
      border-radius: 5px;

      p {
        margin: 5px 0;
      }
    }
  }

  .Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 32px 0 0;

    button {
      width: 120px;
      margin: 0 15px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 600px) {
        width: 110px;
        font-size: 14px;
      }

      @media screen and (max-width: 500px) {
        width: 105px;
        margin: 0 10px;
        font-size: 12px;
      }

      @media screen and (max-width: 440px) {
        width: 100px;
        margin: 0 5px;
        font-size: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }

    @media screen and (max-width: 440px) {
      font-size: 10px;
    }
  }
}

.LogsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 16px;

  & > iframe {
    flex-grow: 1;
    width: 100%;
    border: 1px solid black;
  }

  & > .LogsPageSelects {
    width: fit-content;
    margin: 16px auto 0;
    padding: 10px 8px;
    border: 1px solid black;
    border-radius: 5px;

    & > div {
      display: inline-block;
      margin: 0 8px;

      & > label {
        margin-right: 5px;
      }

      & > select {
        border-radius: 5px;
      }

      @media screen and (max-width: 600px) {
        display: block;

        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
  }
}
